import React, { FC, Fragment, useEffect, useState } from 'react'

import {
  GlobeAltIcon,
  LinkIcon,
  PencilIcon,
  PlusIcon,
  ShoppingBagIcon,
  TrashIcon,
  WrenchScrewdriverIcon,
} from '@heroicons/react/24/outline'
import {
  CardContent,
  Chip,
  InputLabel,
  Tooltip,
  Grid2 as Grid,
} from '@mui/material'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import FormControl from '@mui/material/FormControl'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import type {
  FeatureReleaseStatus,
  FindFeatureDashboardQuery,
  FindFeatureDashboardQueryVariables,
  UpdateFeature,
  UpdateFeatureVariables,
} from 'types/graphql'

import { CellFailureProps, CellSuccessProps, useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import AddFeatureModal from 'src/components/FeatureDashboardCell/AddFeatureModal'
import FeatureOverrideModal from 'src/components/FeatureDashboardCell/FeatureOverrideModal'
import FeatureLinkedProductsListCell from 'src/components/FeatureLinkedProductsListCell'
import Button from 'src/components/Library/Button/Button'
import { default as EmptyComponent } from 'src/components/Library/Empty/Empty'
import IconButton from 'src/components/Library/IconButton'
import { default as LoadingSpinner } from 'src/components/Library/Loading'
import { SearchField } from 'src/components/Library/SearchField/SearchField'
import LinkProductToFeatureCell from 'src/components/LinkProductToFeatureCell'
import Modal from 'src/components/Modal/Modal'
import { useConfirm } from 'src/lib/hooks/Confirmation'
import {
  DELETE_FEATURE_MUTATION,
  UPDATE_FEATURE_MUTATION,
} from 'src/lib/queries/Feature/Feature'

export const QUERY = gql`
  query FindFeatureDashboardQuery {
    features {
      id
      name
      description
      enabled
      releaseStatus
      clientFeature {
        id
        client {
          id
          name
          logomarkUrl
          stripeSetup
        }
        enabled
      }
    }
    clients {
      id
      name
      logomarkUrl
      legacyClient
    }
    clientFeatures {
      id
      clientId
      featureId
      enabled
      limit
      behaviour
      usage
    }
  }
`

export const Loading = () => <LoadingSpinner />

export const Empty = () => <EmptyComponent />

export const Failure = ({
  error,
}: CellFailureProps<FindFeatureDashboardQueryVariables>) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

type Props = CellSuccessProps<
  FindFeatureDashboardQuery,
  FindFeatureDashboardQueryVariables
>

export type Feature = Props['features'][0]
export type Client = Props['clients'][0]

export type ClientFeature = Props['clientFeatures'][0]

export const Success: FC<Props> = ({ features, clients, clientFeatures }) => {
  const menuItems = [
    { name: 'All', value: 'ALL' },
    { name: 'Development', value: 'DEVELOPMENT' },
    { name: 'Internal', value: 'INTERNAL' },
    { name: 'Limited', value: 'LIMITED' },
    { name: 'General', value: 'GENERAL' },
  ]
  const [selectedFeature, setSelectedFeature] = useState(null)
  const [featureStatus, setFeatureStatus] = useState(null)
  const [manageReleaseModal, setManageReleaseModal] = useState(false)
  const [manageLinkProductsModal, setManageLinkProductsModal] = useState(false)
  const [addFeatureModalVisible, setAddFeatureModalVisible] = useState(false)
  const [hotfixModalVisible, setHotfixModalVisible] = useState(false)
  const [filterBy, setFilterBy] = useState('ALL')
  const [loading, setLoading] = useState(false)
  const [featureName, setFeatureName] = useState('')
  const [featureDescription, setFeatureDescription] = useState('')
  const [editFeatureModal, setEditFeatureModal] = useState(false)
  const [linkProductFeatureModal, setLinkProductFeatureModalVisible] =
    useState(false)

  const resetEditFeatureModal = () => {
    if (loading) return
    setEditFeatureModal(false)
    setSelectedFeature(null)
    setFeatureName('')
    setFeatureDescription('')
  }

  const handleUpdateFeature = () => {
    if (loading) return
    setLoading(true)
    updateFeatureStatus({
      variables: {
        id: selectedFeature.id,
        input: {
          name: featureName,
          description: featureDescription,
        },
      },
    }).then(() => {
      resetEditFeatureModal()
    })
  }

  useEffect(() => {
    if (editFeatureModal) {
      setFeatureName(selectedFeature.name)
      setFeatureDescription(selectedFeature.description)
    }
  }, [editFeatureModal])

  const confirm = useConfirm()
  const [updateFeatureStatus] = useMutation<
    UpdateFeature,
    UpdateFeatureVariables
  >(UPDATE_FEATURE_MUTATION, {
    refetchQueries: ['FindFeatureDashboardQuery', 'FindClientFeatureListQuery'],
    awaitRefetchQueries: true,
    onCompleted: () => {
      toast.success('Feature Updated')
      setLoading(false)
    },
    onError: (error) => {
      toast.error('Feature Update Failed')
      toast.error(error.message)
      setLoading(false)
    },
  })

  const [toggleFeatureEnabled] = useMutation<
    UpdateFeature,
    UpdateFeatureVariables
  >(UPDATE_FEATURE_MUTATION, {
    refetchQueries: [
      'FindFeatureDashboardQuery',
      'FindClientFeatureListQuery',
      'GetFeatures',
    ],
    awaitRefetchQueries: true,
    onCompleted: () => {
      toast.success('Feature Updated')
      setLoading(false)
    },
    onError: (error) => {
      toast.error('Feature Update Failed')
      toast.error(error.message)
      setLoading(false)
    },
  })

  const [deleteFeature] = useMutation(DELETE_FEATURE_MUTATION, {
    refetchQueries: [
      'FindFeatureDashboardQuery',
      'FindClientFeatureListQuery',
      'GetFeatures',
    ],
    awaitRefetchQueries: true,
    onCompleted: () => {
      toast.success('Feature Deleted')
      setLoading(false)
    },
    onError: (error) => {
      toast.error('Feature Deletion Failed')
      toast.error(error.message)
      setLoading(false)
    },
  })
  const [featureSearch, setFeatureSearch] = useState('')

  const resetManageRelease = () => {
    if (loading) return
    setSelectedFeature(null)
    setFeatureStatus(null)
    setManageReleaseModal(false)
  }

  const resetManageLinkProducts = () => {
    setSelectedFeature(null)
    setManageLinkProductsModal(false)
  }
  const getFeatureStatusColor = (status: string) => {
    switch (status) {
      case 'INTERNAL':
        return 'bg-yellow-100 text-yellow-800'
      case 'LIMITED':
        return 'bg-blue-100 text-blue-800'
      case 'GENERAL':
        return 'bg-green-100 text-green-800'
      default:
        return 'bg-gray-100 text-gray-800'
    }
  }

  return (
    <>
      <Grid container rowSpacing={2} columnSpacing={3}>
        <Grid size={{ xs: 6 }}>
          <SearchField value={featureSearch} onChange={setFeatureSearch} />
        </Grid>
        <Grid size={{ xs: 3 }}>
          <FormControl fullWidth>
            <InputLabel id="filter-by">Filter By</InputLabel>
            <Select
              fullWidth
              size={'small'}
              label={'Filter By'}
              value={filterBy}
              onChange={(event) => setFilterBy(event.target.value)}
            >
              {menuItems.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid size={{ xs: 3 }}>
          <Button
            onClick={() => setLinkProductFeatureModalVisible(true)}
            startIcon={<LinkIcon className={'h-5 w-5 rotate-45'} />}
          >
            Link Product Feature
          </Button>
        </Grid>
        {features
          .filter((feature) => {
            if (featureSearch === '') return true
            return feature.name
              .toLocaleLowerCase()
              .includes(featureSearch.toLocaleLowerCase())
          })
          .filter((feature) => {
            if (filterBy === 'ALL') return true
            return feature.releaseStatus === filterBy
          })
          .map((feature: Feature, index) => (
            <Fragment key={feature.id}>
              {index === 0 && (
                <Grid size={{ xl: 3, lg: 6, md: 12 }}>
                  <Card
                    variant="outlined"
                    className={'h-full border-2 border-dashed bg-transparent'}
                  >
                    <CardActions className={'h-full p-0'}>
                      <Button
                        onClick={() => {
                          setAddFeatureModalVisible(true)
                        }}
                        className={'h-full'}
                        variant={'text'}
                      >
                        <PlusIcon className={'h-10 w-10'} />
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              )}
              <Grid size={{ xl: 3, lg: 6, md: 12 }} key={feature.id}>
                <Card variant="outlined">
                  <CardContent>
                    <div className="flex justify-between gap-2">
                      <div className="flex flex-col">
                        <div className="text-sm font-semibold">
                          {feature.name}
                        </div>
                        <div className="text-ellipsis text-xs text-gray-500">
                          {feature.description}
                        </div>
                      </div>
                      <div className="flex flex-row items-center gap-1">
                        <Chip
                          className={getFeatureStatusColor(
                            feature.releaseStatus,
                          )}
                          label={feature.releaseStatus}
                        />
                      </div>
                    </div>
                  </CardContent>
                  <CardActions>
                    <Button
                      onClick={() => {
                        if (loading) return
                        setManageReleaseModal(true)
                        setSelectedFeature(feature)
                        setFeatureStatus(feature.releaseStatus)
                      }}
                    >
                      Manage Release
                    </Button>
                    <Tooltip title={'Edit Feature Information'} arrow>
                      <div>
                        <IconButton
                          disabled={loading}
                          className={'hover:!bg-gray-200'}
                          onClick={() => {
                            setSelectedFeature(feature)
                            setEditFeatureModal(true)
                          }}
                        >
                          <PencilIcon className={'`w-5 h-5 stroke-2'} />
                        </IconButton>
                      </div>
                    </Tooltip>
                    <Tooltip title={'Link products'} arrow>
                      <div>
                        <IconButton
                          disabled={loading}
                          className={'hover:!bg-gray-200'}
                          onClick={() => {
                            setSelectedFeature(feature)
                            setManageLinkProductsModal(true)
                          }}
                        >
                          <ShoppingBagIcon className={'`w-5 h-5 stroke-2'} />
                        </IconButton>
                      </div>
                    </Tooltip>
                    <Tooltip
                      title={`Feature ${
                        feature.enabled ? 'ENABLED' : 'DISABLED'
                      } Globally`}
                      arrow
                    >
                      <div>
                        <IconButton
                          className={'hover:!bg-gray-200'}
                          disabled={loading}
                          loading={loading}
                          onClick={() => {
                            confirm({
                              title:
                                'Are you sure you want to toggle this feature?',
                              description:
                                'This action will affect all clients.',
                            }).then((isConfirmed) => {
                              if (!isConfirmed) return
                              setLoading(true)
                              toggleFeatureEnabled({
                                variables: {
                                  id: feature.id,
                                  input: {
                                    enabled: !feature.enabled,
                                  },
                                },
                              })
                            })
                          }}
                        >
                          <GlobeAltIcon
                            className={`h-5 w-5 stroke-2 ${
                              feature.enabled && 'text-green-400'
                            }`}
                          />
                        </IconButton>
                      </div>
                    </Tooltip>
                    <Tooltip title={'Manage Client Access'} arrow>
                      <div>
                        <IconButton
                          disabled={loading}
                          className={'hover:!bg-gray-200'}
                          onClick={() => {
                            setSelectedFeature(feature)
                            setHotfixModalVisible(true)
                          }}
                        >
                          <WrenchScrewdriverIcon
                            className={'`w-5 h-5 stroke-2'}
                          />
                        </IconButton>
                      </div>
                    </Tooltip>
                    <Tooltip title={'Delete'} arrow>
                      <div>
                        <IconButton
                          disabled={loading}
                          className={'hover:!bg-red-200'}
                          onClick={() => {
                            confirm({
                              title:
                                'Are you sure you want to delete this feature?',
                              description: 'This action cannot be undone.',
                            }).then(() => {
                              setLoading(true)
                              deleteFeature({
                                variables: {
                                  id: feature.id,
                                },
                              })
                            })
                          }}
                        >
                          <TrashIcon className={'`w-5 h-5 stroke-2'} />
                        </IconButton>
                      </div>
                    </Tooltip>
                  </CardActions>
                </Card>
              </Grid>
            </Fragment>
          ))}
      </Grid>
      <Modal
        open={editFeatureModal}
        title={'Edit Feature'}
        onClose={resetEditFeatureModal}
        dialogClassName={'max-w-4xl'}
        childClassName={'p-4'}
        footerVisible
        onCancel={resetEditFeatureModal}
        onConfirm={handleUpdateFeature}
        loading={loading}
      >
        <div className={'flex flex-col gap-4'}>
          <Tooltip
            title={
              'The Identifier of the feature can not be changed once created'
            }
            placement={'top'}
            arrow
            followCursor
          >
            <TextField
              label={'Feature Name'}
              value={featureName}
              onChange={(event) => setFeatureName(event.target.value)}
              fullWidth
              disabled={true}
            />
          </Tooltip>
          <TextField
            label={'Feature Description'}
            value={featureDescription}
            onChange={(event) => setFeatureDescription(event.target.value)}
            fullWidth
          />
        </div>
      </Modal>
      <Modal
        open={manageReleaseModal}
        onClose={resetManageRelease}
        title={`Manage Release ${selectedFeature?.name}`}
        dialogClassName={'bg-gray-100 max-h-1/3 overflow-hidden'}
        childClassName={'p-4'}
      >
        <div className={''}>
          {!loading ? (
            <>
              <div className="text-sm font-semibold">Release Status</div>
              <Select
                disabled={loading}
                value={featureStatus}
                onChange={(event) => {
                  setLoading(true)
                  setFeatureStatus(event.target.value as string)
                  updateFeatureStatus({
                    variables: {
                      id: selectedFeature.id,
                      input: {
                        releaseStatus: event.target
                          .value as FeatureReleaseStatus,
                      },
                    },
                  })
                }}
                fullWidth
              >
                {menuItems.slice(1, menuItems.length).map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
              <p className={'text-xs text-gray-400'}>
                Changing a release will start an automated script to release to
                specific clients depending on the release status.
              </p>
            </>
          ) : (
            <LoadingSpinner />
          )}
        </div>
      </Modal>
      <Modal
        open={manageLinkProductsModal}
        onClose={resetManageLinkProducts}
        title={`Manage Link Products - ${selectedFeature?.name}`}
        dialogClassName={'overflow-hidden max-w-4xl'}
        childClassName={'p-8'}
        className={'absolute'}
      >
        <FeatureLinkedProductsListCell featureId={selectedFeature?.id} />
      </Modal>
      <AddFeatureModal
        featureModalVisible={addFeatureModalVisible}
        setFeatureModalVisible={setAddFeatureModalVisible}
      />
      <FeatureOverrideModal
        feature={selectedFeature}
        overrideModalVisible={hotfixModalVisible}
        setOverrideModalVisible={setHotfixModalVisible}
        clients={clients}
        clientsWithFeature={clientFeatures}
      />
      {linkProductFeatureModal && (
        <LinkProductToFeatureCell
          isOpen={linkProductFeatureModal}
          close={() => {
            setLinkProductFeatureModalVisible(false)
          }}
        />
      )}
    </>
  )
}
