import { FC, useState } from 'react'

// import Modal from '@mui/material/Modal'
import { TrashIcon } from '@heroicons/react/24/outline'
import Autocomplete from '@mui/material/Autocomplete'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import type {
  CreateProductFeature,
  CreateProductFeatureVariables,
  DeleteProductFeature,
  DeleteProductFeatureVariables,
  FindFeatureLinkedProductsListQuery,
  FindFeatureLinkedProductsListQueryVariables,
  UpdateProductFeature,
  UpdateProductFeatureVariables,
} from 'types/graphql'

import { CellFailureProps, CellSuccessProps, useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import Button from 'src/components/Library/Button/Button'
import { default as EmptyComponent } from 'src/components/Library/Empty/Empty'
import IconButton from 'src/components/Library/IconButton'
import { default as LoadingSpinner } from 'src/components/Library/Loading'
import Modal from 'src/components/Modal/Modal'
import { useConfirm } from 'src/lib/hooks/Confirmation'
import {
  CREATE_PRODUCT_FEATURE,
  DELETE_PRODUCT_FEATURE,
  UPDATE_PRODUCT_FEATURE,
} from 'src/lib/queries/ProductFeatures/ProductFeatures'

export const QUERY = gql`
  query FindFeatureLinkedProductsListQuery($featureId: Int!) {
    products: productFeaturesBoard {
      id
      name
    }
    linkedProducts: productsByFeatureId(featureId: $featureId) {
      id
      stripeProductId
      featureId
      usagePerSeat
    }
  }
`

export const Loading = () => <LoadingSpinner />

export const Empty = () => <EmptyComponent />

export const Failure = ({
  error,
}: CellFailureProps<FindFeatureLinkedProductsListQueryVariables>) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

type Props = CellSuccessProps<
  FindFeatureLinkedProductsListQuery,
  FindFeatureLinkedProductsListQueryVariables
> & { featureId: number }

type LinkedProduct = Props['linkedProducts'][0]
type Product = Props['products'][0]
export const Success: FC<Props> = ({ linkedProducts, products, featureId }) => {
  const [open, setOpen] = useState(false)
  const [productsToLink, setProductsToLink] = useState<Product | undefined>(
    undefined,
  )
  const filteredProducts = products.filter((product) => {
    const linkedProductIds = linkedProducts.map(
      (linkedProduct) => linkedProduct.stripeProductId,
    )
    return !linkedProductIds.includes(product.id)
  })
  const [usagePerSeat, setUsagePerSeat] = useState<number>(0)
  const [loading, setLoading] = useState(false)

  const confirm = useConfirm()

  const [createProductFeatures] = useMutation<
    CreateProductFeature,
    CreateProductFeatureVariables
  >(CREATE_PRODUCT_FEATURE, {
    onCompleted: () => {
      toast.success('Product Feature Created')
      setLoading(false)
    },
    onError: (error) => {
      toast.error(error.message)
      setLoading(false)
    },
    refetchQueries: ['FindFeatureLinkedProductsListQuery'],
    awaitRefetchQueries: true,
  })

  const [updateProductFeatures] = useMutation<
    UpdateProductFeature,
    UpdateProductFeatureVariables
  >(UPDATE_PRODUCT_FEATURE, {
    onCompleted: () => {
      toast.success('Product Feature Updated')
    },
    onError: (error) => {
      toast.error(error.message)
    },
    refetchQueries: ['FindFeatureLinkedProductsListQuery'],
    awaitRefetchQueries: true,
  })

  const [deleteProductFeatures] = useMutation<
    DeleteProductFeature,
    DeleteProductFeatureVariables
  >(DELETE_PRODUCT_FEATURE, {
    onCompleted: () => {
      toast.success('Product Feature Deleted')
    },
    onError: (error) => {
      toast.error(error.message)
    },
    refetchQueries: ['FindFeatureLinkedProductsListQuery'],
    awaitRefetchQueries: true,
  })

  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 70 },
    {
      field: 'name',
      headerName: 'Product',
      minWidth: 200,
      flex: 1,
      valueGetter: (_value, row) =>
        products.find((product) => row.stripeProductId === product.id)?.name,
    },
    {
      field: 'usagePerSeat',
      headerName: 'Usage Per Seat',
      width: 130,
      flex: 1,
      editable: true,
      type: 'number',
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 130,
      flex: 1,
      renderCell: (params) => {
        return (
          <IconButton
            color={'error'}
            className={'hover:!bg-red-300'}
            onClick={() => {
              confirm({
                title:
                  'Are you sure you want to remove this product from feature?',
                description:
                  'This action will affect clients access and usage, this cannot be undone.',
              }).then((isConfirmed) => {
                if (!isConfirmed) return
                deleteProductFeatures({
                  variables: {
                    id: params.row.id,
                  },
                })
              })
            }}
          >
            <TrashIcon className={'h-5 w-5'} />
          </IconButton>
        )
      },
    },
  ]

  const onModalClose = () => {
    if (loading) return
    setProductsToLink(undefined)
    setUsagePerSeat(0)
    setOpen(false)
  }

  return (
    <Stack spacing={1}>
      <Button onClick={() => setOpen(true)} variant={'outlined'}>
        Add Product
      </Button>
      <DataGrid
        autoHeight
        rows={linkedProducts}
        columns={columns}
        loading={loading}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
        }}
        processRowUpdate={(newRow) => {
          updateProductFeatures({
            variables: {
              id: newRow.id,
              input: {
                usagePerSeat: newRow.usagePerSeat,
              },
            },
          })

          return newRow
        }}
      />
      <Modal
        open={open}
        loading={loading}
        onClose={() => {
          onModalClose()
        }}
        title={'Add Product'}
        className={'absolute left-1/4'}
        dialogClassName={'overflow-visible'}
        childClassName={'p-4'}
      >
        <Stack spacing={2}>
          <Autocomplete
            options={filteredProducts}
            getOptionLabel={(option) => option.name}
            fullWidth
            renderInput={(params) => <TextField {...params} label="Product" />}
            onChange={(_, value) => {
              if (value) {
                setProductsToLink(value)
              } else {
                setProductsToLink(undefined)
              }
            }}
          />
          <TextField
            fullWidth
            label="Usage Per Seat"
            placeholder="Enter number"
            variant="outlined"
            type="number"
            defaultValue={0}
            onChange={(event) => {
              setUsagePerSeat(parseInt(event.target.value))
            }}
          />
          <Button
            disabled={productsToLink === undefined}
            onClick={() => {
              if (productsToLink) {
                setLoading(true)
                createProductFeatures({
                  variables: {
                    input: {
                      featureId,
                      stripeProductId: productsToLink.id,
                      usagePerSeat: usagePerSeat,
                    },
                  },
                }).then(() => {
                  setOpen(false)
                  setProductsToLink(undefined)
                  setUsagePerSeat(0)
                })
              }
            }}
            loading={loading}
          >
            Add
          </Button>
        </Stack>
      </Modal>
    </Stack>
  )
}
